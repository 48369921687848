import './main.scss';
import initMultiQtyProductOptions from './multi-qty-product-options';

const main = async () => {
    console.log('Multi Qty Product Options by PapaThemes');

    if (!$) {
        console.error('window.jQueryTheme is not installed. Multi Qty Product Options cannot run.');
        return;
    }

    if (STORE_DOMAIN !== 'public') {
        if (window.location.hostname !== 'localhost'
        && !window.location.hostname.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/)
        && window.location.host !== STORE_DOMAIN
        && window.location.host !== 'www.' + STORE_DOMAIN) {
            console.log('Invalid store');
            return;
        }
    }

    initMultiQtyProductOptions(window.PapathemesMultiQtyProductOptionsSettings);
};

main();
