import { debounce } from "debounce";
import Mustache from "mustache";
import queryString from "query-string";
import Swal from 'sweetalert2'

//
// https://javascript.info/task/delay-promise
//
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//
// https://hackernoon.com/functional-javascript-resolving-promises-sequentially-7aac18c4431e
//
function promiseSerial(funcs) {
    return funcs.reduce(
        (promise, func) => promise.then(result => func().then(Array.prototype.concat.bind(result))),
        Promise.resolve([]),
    );
}

function priceVal(s) {
    return s.replace(/[^0-9]*([0-9]+(\.[0-9]+)?)[^0-9]*$/, '$1');
}

function priceFormat(val, sample) {
    return sample.replace(/[0-9]+(\.[0-9]+)?$/, Number(val).toFixed(2));
}

class Product {
    constructor($scope, {
        fields,
        productOptionsSelector = '[data-product-option-change]',
        optionLabelSelector = 'label',
        formFieldSelector = '.form-field[data-product-attribute]',
        quantityFormFieldSelector = '.form-field--increments',
        productIdSelector = 'input[name=product_id]',
        productViewSelector = '.productView',
        productTitleSelector = '.productView-title',
        priceWithTaxSelector = '[data-product-price-with-tax]',
        priceWithoutTaxSelector = '[data-product-price-without-tax]',
        productOptionsListSelector = '.productOptions-list',
        productListTemplate = `
            <div class="mqpo-productsList _<%type%>">
                <%#list%>
                <div class="_item" data-mqpo-attribute-item>
                    <%#imgTag%><div class="_img" data-img><%&.%></div><%/imgTag%>
                    <div class="_title" data-name><%name%></div>
                    <div class="_props">
                        <span class="price price--withTax" data-mqpo-price-with-tax style="display:none"></span> <span class="_price-label" data-mqpo-price-with-tax-label style="display:none">(Incl. <span data-mqpo-tax-label></span>)</span>
                        <span class="price price--withoutTax" data-mqpo-price-without-tax style="display:none"></span> <span class="_price-label" data-mqpo-price-without-tax-label style="display:none">(Excl. <span data-mqpo-tax-label></span>)</span>
                        <span class="_sku-label" data-mqpo-sku-label style="display:none">SKU:</span> <span class="_sku-value" data-mqpo-sku style="display:none"></span>
                        <span class="_stock-label" data-mqpo-stock-label style="display:none">Stock:</span> <span class="_stock-value" data-mqpo-stock style="display:none"></span>
                        <span class="_stock-msg" data-mqpo-stock-message style="display:none"></span>
                    </div>
                    <div class="_qty">
                        <div class="form-increment" data-mqpo-quantity-change>
                            <button class="button button--icon" data-action="dec">
                                <span class="is-srOnly">Decrease Quantity:</span>
                                <i class="icon" aria-hidden="true"><svg><use xlink:href="#icon-keyboard-arrow-down"></use></svg></i>
                            </button>
                            <input class="form-input form-input--incrementTotal" id="qty_<%id%>" data-mqpo-attribute-id="<%attrId%>" data-mqpo-attribute-value="<%attrVal%>" type="tel" value="0" data-quantity-min="<%min%>" data-quantity-max="<%max%>" min="0" pattern="[0-9]*" aria-live="polite">
                            <button class="button button--icon" data-action="inc">
                                <span class="is-srOnly">Increase Quantity:</span>
                                <i class="icon" aria-hidden="true"><svg><use xlink:href="#icon-keyboard-arrow-up"></use></svg></i>
                            </button>
                        </div>
                    </div>
                </div>
                <%/list%>
            </div>`,
        productTableTemplate = `
            <div class="mqpo-productsTable">
                <div class="_row">
                    <%#columnNames%>
                        <div class="_col"><%&.%></div>
                    <%/columnNames%>
                </div>
                <%#rows%>
                    <div class="_row">
                        <div class="_col"><span class="_name"><%&name%></span></div>
                        <%#columns%>
                            <div class="_col" data-mqpo-attribute-item>
                                <span style="display:none" data-name><%fullName%></span>
                                <span class="_name"><%&name%></span>
                                <span class="price price--withTax" data-mqpo-price-with-tax style="display:none"></span> <span class="_price-label" data-mqpo-price-with-tax-label style="display:none">(Incl. <span data-mqpo-tax-label></span>)</span>
                                <span class="price price--withoutTax" data-mqpo-price-without-tax style="display:none"></span> <span class="_price-label" data-mqpo-price-without-tax-label style="display:none">(Excl. <span data-mqpo-tax-label></span>)</span>
                                <div class="form-increment" data-mqpo-quantity-change>
                                    <button class="button button--icon" data-action="dec">
                                        <span class="is-srOnly">Decrease Quantity:</span>
                                        <i class="icon" aria-hidden="true"><svg><use xlink:href="#icon-keyboard-arrow-down"></use></svg></i>
                                    </button>
                                    <input class="form-input form-input--incrementTotal" id="qty_<%id%>" data-mqpo-attribute-id="<%attrId%>" data-mqpo-attribute-value="<%attrVal%>" type="number" value="" data-quantity-min="<%min%>" data-quantity-max="<%max%>" min="0" pattern="[0-9]*" aria-live="polite">
                                    <button class="button button--icon" data-action="inc">
                                        <span class="is-srOnly">Increase Quantity:</span>
                                        <i class="icon" aria-hidden="true"><svg><use xlink:href="#icon-keyboard-arrow-up"></use></svg></i>
                                    </button>
                                </div>
                            </div>
                        <%/columns%>
                    </div>
                <%/rows%>
            </div>
        `,
        addedToCartMsgTemplate = `
            <div class="mqpo-addedToCartMsg-content">
                <div class="_msg"><%msg%></div>
                <ul class="_list">
                    <%#list%>
                        <li class="_item">
                            <%#imgTag%><div class="_img"><%&.%></div><%/imgTag%>
                            <div class="_title"><%&name%></div>
                            <div class="_qty"><%qty%></div>
                        </li>
                    <%/list%>
                </ul>
                <div class="_actions">
                    <a class="button button--primary" href="/checkout.php">Checkout Now</a>
                    <a class="button" href="/cart.php">View your Cart</a>
                </div>
            </div>
        `,
        addedToCartMsgTitle = 'Added to your cart!',
        addedToCartMsgWithProductName = false,
        cartContentFile = 'cart/content', // Only need for old stencil-utils 1.x
        hideNoneOption = false,
        hideMainPrice = false,
        hideMainQty = false,
        useThemeAddToCartForMainProduct = false,
        redirectToCart = false,
        delayAddToCart = 500,
        cartId = null,
    }) {
        this.$scope = $scope;
        this.fields = fields;
        this.productOptionsSelector = productOptionsSelector;
        this.optionLabelSelector = optionLabelSelector;
        this.formFieldSelector = formFieldSelector;
        this.quantityFormFieldSelector = quantityFormFieldSelector;
        this.productIdSelector = productIdSelector;
        this.productId = Number($scope.find(this.productIdSelector).val());
        this.productViewSelector = productViewSelector;
        this.productTitleSelector = productTitleSelector;
        this.priceWithTaxSelector = priceWithTaxSelector;
        this.priceWithoutTaxSelector = priceWithoutTaxSelector;
        this.productOptionsListSelector = productOptionsListSelector;
        this.productListTemplate = productListTemplate;
        this.productTableTemplate = productTableTemplate;
        this.addedToCartMsgTemplate = addedToCartMsgTemplate;
        this.addedToCartMsgTitle = addedToCartMsgTitle;
        this.addedToCartMsgWithProductName = addedToCartMsgWithProductName;
        this.cartContentFile = cartContentFile;
        this.hideNoneOption = hideNoneOption;
        this.hideMainPrice = hideMainPrice;
        this.hideMainQty = hideMainQty;
        this.useThemeAddToCartForMainProduct = useThemeAddToCartForMainProduct;
        this.redirectToCart = redirectToCart;
        this.delayAddToCart = delayAddToCart;
        this.cartId = cartId;
        this.fieldByLabel = this.mapFieldByLabel();
        this.isTable = false;

        if (!this.productId) {
            return;
        }
        
        this.initOptionsView();

        if (this.$scope.find('[data-mqpo-quantity-change]').length > 0) {
            // Hide the default quantity field
            if (this.hideMainQty) {
                this.$scope.find(this.quantityFormFieldSelector).hide();
            }

            this.bindEvents();
            this.updateAllItems();
        }
    }

    bindEvents() {
        this.listenQuantityChange();

        this.$scope.find(this.productOptionsSelector).on('change', event => {
            this.updateAllItems();
        });

        this.$scope.off('submit').on('submit', event => {
            this.addToCart(event);
        });
    }

    async addToCart(event = null, isAlsoBought = false) {
        event && event.preventDefault();

        const promises = [];
        const form = this.$scope.get(0);
        const $qty = this.$scope.find(this.quantityFormFieldSelector).find('input');
        const mainQty = Number($qty.val());

        this.$scope.find('[data-mqpo-quantity-change] input').each((i, el) => {
            const $el = $(el);

            // If not hideMainQty, multiply child product qty by the main qty
            const qty = Number($el.val()) * (this.hideMainQty ? 1 : mainQty);

            if (qty <= 0) {
                return;
            }

            const attrIds = String($el.data('mqpoAttributeId')).split(',');
            const attrVals = String($el.data('mqpoAttributeValue')).split(',');

            promises.push(async () => {
                // Fill qty field
                $qty.val(qty);

                // Add attribute[] field coresponding to the option item
                const formData = this.filterEmptyFilesFromForm(new FormData(form));

                for (let i = 0; i < attrIds.length; i++) {
                    formData.append(`attribute[${attrIds[i]}]`, attrVals[i]);
                }

                // Add the item to cart
                const response = await this.addItemToCart(formData);

                // Reset qty input after added to cart successfully
                if (response && response.data && !response.data.error) {
                    $el.val(0);
                }

                // delay before processing the next request
                await delay(this.delayAddToCart);

                return {
                    response,
                    $item: $el.closest('[data-mqpo-attribute-item]'),
                }
            });
        });

        // If no child products selected & show main qty,
        // then add the main product to cart
        if (promises.length === 0 && !this.hideMainQty) {
            if (this.useThemeAddToCartForMainProduct) {
                // propagate to the theme onSubmit event
                // to process add to cart for the main product
                return;
            }

            promises.push(async () => {
                // Add attribute[] field coresponding to the option item
                const formData = this.filterEmptyFilesFromForm(new FormData(form));

                // Add the item to cart and wait
                const response = await this.addItemToCart(formData);
                await delay(this.delayAddToCart);

                return {
                    response,
                }
            });
        }

        event && event.stopImmediatePropagation();

        const $addToCartBtn = $('#form-action-addToCart', event ? $(event.target) : this.$scope);
        const originalBtnVal = $addToCartBtn.val();
        const waitMessage = $addToCartBtn.data('waitMessage');

        if (!isAlsoBought) {
            $addToCartBtn
                .val(waitMessage)
                .prop('disabled', true);
        }

        const results = await promiseSerial(promises);
        const successList = [];
        
        results.forEach(result => {
            if (result.response && result.response.data.cart_item) {
                const productName = this.$scope.closest(this.productViewSelector).find(this.productTitleSelector).text();
                if (result.$item) {
                    const name = (this.addedToCartMsgWithProductName ? `${productName} - ` : '') + result.$item.find('[data-name]').text();
                    const imgTag = result.$item.find('[data-img]').html() || `<img src="${result.response.data.cart_item.thumbnail}" class="_img" />`;
                    const qty = result.$item.find('[data-mqpo-quantity-change] input').val();
                    successList.push({ name, imgTag, qty });
                } else {
                    successList.push({
                        name: productName,
                        imgTag: `<img src="${result.response.data.cart_item.thumbnail}" class="_img" />`,
                        qty: $qty.val(),
                    });
                }
            }
        });

        // Only proceed if this is a main product view, not also-bought
        if (!isAlsoBought) {
            const alsoBoughts = this.$scope.closest(`${this.productViewSelector}, [data-also-bought]`).find('[data-also-bought]').get().map(el => $(el).data('alsoBoughtInstance')).filter(alsoBought => alsoBought);
            alsoBoughts.forEach(alsoBought => {
                // Override addProductToCart() to use mpqo addToCart() function instead
                if (!alsoBought.addProductToCart_mpqoBackup) {
                    alsoBought.requestDelay = this.delayAddToCart;
                    alsoBought.addProductToCart_mpqoBackup = alsoBought.addProductToCart;
                    alsoBought.addProductToCart = async (product) => {
                        const mqpo = product.$scope.find('form[data-cart-item-add]').data(`${APPUID}ProductInstance`);                    
                        if (mqpo && mqpo.$scope.find('[data-mqpo-quantity-change]').length > 0) {
                            return await mqpo.addToCart(null, true);
                        }
                        return await alsoBought.addProductToCart_mpqoBackup(product);
                    };
                }
            });
            const alsoBoughtPromises = alsoBoughts.map(alsoBought => (async () => await alsoBought.parentProductAddedToCart()));
            const alsoBoughtResults = await promiseSerial(alsoBoughtPromises);
            const alsoBoughtFailed = alsoBoughtResults.filter(result => !result).length > 0;

            if (successList.length > 0) {
                this.getCartQuantity((err, qty) => {
                    $('body').trigger('cart-quantity-update', qty);
                });

                const html = Mustache.render(this.addedToCartMsgTemplate, { list: successList }, null, ['<%', '%>']);

                if (this.redirectToCart) {
                    if (!alsoBoughtFailed) {
                        window.location = '/cart.php';
                        return;
                    }
                } else {
                    Swal.fire({
                        title: this.addedToCartMsgTitle,
                        html,
                        showCloseButton: true,
                        showConfirmButton: false,
                        customClass: 'mqpo-swal-popup',
                    });
                }
            } else {
                if (alsoBoughtResults.length > 0 && !alsoBoughtFailed) {
                    window.location = '/cart.php';
                    return;
                }
            }

            $addToCartBtn
                .val(originalBtnVal)
                .prop('disabled', false);
        } else {
            if (successList.length !== results.length) {
                throw new Exception("Added to cart failed");
            }
        }
    }

    async addItemToCart(formData) {
        return new Promise((resolve) => {
            stencilUtils.api.cart.itemAdd(formData, async (err, response) => {
                const errorMessage = err || response.data.error;
                if (errorMessage) {
                    await Swal.fire({
                        icon: 'error',
                        text: errorMessage,
                        showCloseButton: true,
                        showConfirmButton: false,
                        customClass: 'mqpo-swal-popup',
                    });
                }

                resolve(response);
            });
        });
    }

    getCartQuantity(callback) {
        if (stencilUtils.MAJOR_VERSION && stencilUtils.MAJOR_VERSION === 1) {
            stencilUtils.api.cart.getContent({ template: this.cartContentFile }, (err, response) => {
                const quantity = $(response).find('[data-cart-quantity]').data('cart-quantity') || 0;
                callback(err, quantity);
            });
        } else {
            stencilUtils.api.cart.getCartQuantity({ cartId: this.cartId }, callback);
        }
    }

    /**
     * https://stackoverflow.com/questions/49672992/ajax-request-fails-when-sending-formdata-including-empty-file-input-in-safari
     * Safari browser with jquery 3.3.1 has an issue uploading empty file parameters. This function removes any empty files from the form params
     * @param formData: FormData object
     * @returns FormData object
     */
    filterEmptyFilesFromForm(formData) {
        try {
            for (const [key, val] of formData) {
                if (val instanceof File && !val.name && !val.size) {
                    formData.delete(key);
                }
            }
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
        return formData;
    }

    listenQuantityChange() {
        this.$scope.on('click', '[data-mqpo-quantity-change] button', event => {
            event.preventDefault();
            const $target = $(event.currentTarget);
            const $input = $target.closest('[data-mqpo-quantity-change]').find('input');
            const quantityMin = parseInt($input.data('quantityMin'), 10);
            const quantityMax = parseInt($input.data('quantityMax'), 10);
            const stock = $input.data('stock');

            let qty = parseInt($input.val() || 0, 10);

            // If action is incrementing
            if ($target.data('action') === 'inc') {
                // If quantity max option is set
                if (quantityMax > 0) {
                    // Check quantity does not exceed max
                    if ((qty + 1) <= quantityMax) {
                        qty++;
                    }
                } else if (quantityMin > 0) {
                    if ((qty + 1) < quantityMin) {
                        qty = quantityMin;
                    } else {
                        qty++;
                    }
                } else {
                    qty++;
                }
            } else if (qty > 0) {
                // If quantity min option is set
                if (quantityMin > 0) {
                    // Check quantity does not fall below min
                    if ((qty - 1) >= quantityMin) {
                        qty--;
                    } else {
                        qty = 0;
                    }
                } else {
                    qty--;
                }
            }
            $input.val(qty).trigger('change');
        });

        this.$scope.find('[data-mqpo-quantity-change] input')
            .on('keypress', event => {
                if (event.keyCode === 13) {
                    event.preventDefault();
                }
            })
            .on('change', event => {
                event.stopImmediatePropagation();

                const $input = $(event.target);
                const quantityMin = parseInt($input.data('quantityMin'), 10);
                const quantityMax = parseInt($input.data('quantityMax'), 10);
                const stock = $input.data('stock');
                let qty = parseInt($input.val() || 0, 10);

                if (qty < quantityMin) {
                    qty = quantityMin;
                }

                if (quantityMax > 0 && qty > quantityMin) {
                    qty = quantityMin;
                }

                if (stock !== null && qty > stock) {
                    qty = stock;
                }

                $input.val(qty);

                if (this.isTable && $input.val() === '0') {
                    $input.val('');
                }
                
                this.updateItem($input);
            })
            .on('focus', event => {
                if (event.target.select) {
                    event.target.select();
                }
            });
    }

    updateAllItems() {
        const $input = this.$scope.find('[data-mqpo-quantity-change] input');
        if (this.isTable) {
            $input.filter((i, input) => Number($(input).val()) > 0).trigger('change');
        } else {
            $input.trigger('change');
        }
    }

    updateItem($input) {
        const params = queryString.parse(this.$scope.serialize());
        params['qty[]'] = $input.val();

        const keys = String($input.data('mqpoAttributeId')).split(',');
        const vals = String($input.data('mqpoAttributeValue')).split(',');
        for (let i = 0; i < keys.length; i++) {
            params[`attribute[${keys[i]}]`] = vals[i];
        }

        const callback = (err, resp) => {
            // Update price of the option item (child product)
            const $item = $input.closest('[data-mqpo-attribute-item]');
            if (resp.data.price && resp.data.price.with_tax) {
                $item.find('[data-mqpo-price-with-tax]').html(resp.data.price.with_tax.formatted).show();
                $item.find('[data-mqpo-price-with-tax-label]').show();
            } else {
                $item.find('[data-mqpo-price-with-tax]').html('').hide();
                $item.find('[data-mqpo-price-with-tax-label]').hide();
            }
            if (resp.data.price && resp.data.price.without_tax) {
                $item.find('[data-mqpo-price-without-tax]').html(resp.data.price.without_tax.formatted).show();
                $item.find('[data-mqpo-price-without-tax-label]').show();
            } else {
                $item.find('[data-mqpo-price-without-tax]').html('').hide();
                $item.find('[data-mqpo-price-without-tax-label]').hide();
            }
            if (resp.data.price) {
                $item.find('[data-mqpo-tax-label]').html(resp.data.price.tax_label);
            }
            if (resp.data.sku) {
                $item.find('[data-mqpo-sku]').html(resp.data.sku).show();
                $item.find('[data-mqpo-sku-label]').show();
            } else {
                $item.find('[data-mqpo-sku]').html('').hide();
                $item.find('[data-mqpo-sku-label]').hide();
            }
            if (resp.data.stock_message) {
                $item.find('[data-mqpo-stock-message]').html(resp.data.stock_message).show();
            } else {
                $item.find('[data-mqpo-stock-message]').html('').hide();
            }
            if (resp.data.stock) {
                $item.find('[data-mqpo-stock]').html(resp.data.stock).show();
                $item.find('[data-mqpo-stock-label]').show();
            } else {
                $item.find('[data-mqpo-stock]').html('').hide();
                $item.find('[data-mqpo-stock-label]').hide();
                $input.data('stock', null);
            }

            if (resp.data.stock !== null) {
                $input.data('stock', resp.data.stock);
                if (Number($input.val()) > resp.data.stock) {
                    $input.val(resp.data.stock);
                }
            } else {
                $input.data('stock', null);
            }

            if (!resp.data.purchasable || !resp.data.instock) {
                $item.find('input, button').prop('disabled', true);
            } else {
                $item.find('input, button').prop('disabled', false);
            }

            if (this.isTable) {
                if (!resp.data.purchasable) {
                    Swal.fire({
                        text: resp.data.purchasing_message || 'Cannot purchase this product',
                    }).then(() => {
                        $input.val($input.data('lastValue') || 0);
                    });
                } else {
                    $input.data('lastValue', $input.val());
                }
            }

            this.updateTotalPrice();
        };

        if (stencilUtils.MAJOR_VERSION && stencilUtils.MAJOR_VERSION === 1) {
            stencilUtils.api.productAttributes.optionChange(this.productId, queryString.stringify(params), callback);
        } else {
            stencilUtils.api.productAttributes.optionChange(this.productId, queryString.stringify(params), null, callback);
        }
    }

    updateTotalPrice() {
        let priceWithTax = 0;
        let priceWithoutTax = 0;
        this.$scope.find('[data-mqpo-attribute-item]').each((i, el) => {
            const $el = $(el);
            priceWithTax += priceVal($el.find('[data-mqpo-price-with-tax]').text()) * Number($el.find('[data-mqpo-quantity-change] input').val());
            priceWithoutTax += priceVal($el.find('[data-mqpo-price-without-tax]').text()) * Number($el.find('[data-mqpo-quantity-change] input').val());
        });

        const $priceWithTax = this.$scope.closest(this.productViewSelector).find(this.priceWithTaxSelector);
        const $priceWithoutTax = this.$scope.closest(this.productViewSelector).find(this.priceWithoutTaxSelector);

        if ($priceWithTax.length > 0) {
            if (!$priceWithTax.data('mqpoOriginalPrice')) {
                $priceWithTax.data('mqpoOriginalPrice', $priceWithTax.html());
            }
            if (priceWithTax > 0) {
                $priceWithTax
                    .html(priceFormat(priceWithTax, $priceWithTax.text()))
                    .show();
            } else {
                $priceWithTax.html($priceWithTax.data('mqpoOriginalPrice'));
                if (this.hideMainPrice) {
                    $priceWithTax.hide();
                } else {
                    $priceWithTax.show();
                }
            }
        }
        if ($priceWithoutTax.length > 0) {
            if (!$priceWithoutTax.data('mqpoOriginalPrice')) {
                $priceWithoutTax.data('mqpoOriginalPrice', $priceWithoutTax.html());
            }
            if (priceWithoutTax > 0) {
                $priceWithoutTax
                    .html(priceFormat(priceWithoutTax, $priceWithoutTax.text()))
                    .show();
            } else {
                $priceWithoutTax.html($priceWithoutTax.data('mqpoOriginalPrice'));
                if (this.hideMainPrice) {
                    $priceWithoutTax.hide();
                } else {
                    $priceWithoutTax.show();
                }
            }
        }
    }

    initOptionsView() {
        const $qty = this.$scope.find('[data-quantity-change] input');
        const min = $qty.data('quantityMin');
        const max = $qty.data('quantityMax');

        this.fields.forEach(({ name, productIds = [] }) => {
            // Ignore if product ID doesn't match
            if (productIds.length > 0 && productIds.indexOf(this.productId) === -1) {
                return;
            }

            // if name is an array, will display as a 2d table
            if (typeof name === 'object') {
                const [field1, field2] = name.map(s => this.fieldByLabel[s.toUpperCase()]);

                // Ignore if there is any option not found
                if (!field1 || !field2) {
                    return;
                }

                const [list1, list2] = [field1, field2].map(field => [
                    ...field.$div.filter('[data-product-attribute="set-select"]')
                        .find('option[data-product-attribute-value]')
                        .get()
                        .map(el => $(el))
                        .map($el => ({
                            name: $el.text().trim(),
                            attrVal: $el.attr('value'),
                            attrId: $el.parent().attr('name').replace(/attribute\[(.+)\]/, '$1'),
                        })),
                    ...field.$div.filter('[data-product-attribute="set-rectangle"]')
                        .children('input')
                        .get()
                        .map(el => $(el))
                        .map($el => ({
                            name: $el.next('label').text().trim(),
                            attrVal: $el.attr('value'),
                            attrId: $el.attr('name').replace(/attribute\[(.+)\]/, '$1'),
                        })),
                    ...field.$div.filter('[data-product-attribute="swatch"]')
                        .children('input')
                        .get()
                        .map(el => $(el))
                        .map($el => ({
                            name: $el.next('label').html(),
                            attrVal: $el.attr('value'),
                            attrId: $el.attr('name').replace(/attribute\[(.+)\]/, '$1'),
                        })),
                ]);
                // console.log(list1);
                // console.log(list2);

                const columnNames = [`${name.join(' / ')}:`, ...list2.map(item => item.name)];
                // console.log(columnNames);

                const rows = list1.map(row => ({
                    name: row.name,
                    columns: list2.map(col => ({
                        fullName: `${row.name} - ${col.name}`,
                        name: col.name,
                        id: `${this.productId}_${row.attrId}_${row.attrVal}_${col.attrId}_${col.attrVal}`,
                        attrId: `${row.attrId},${col.attrId}`,
                        attrVal: `${row.attrVal},${col.attrVal}`,
                    })),
                }));

                const html = Mustache.render(this.productTableTemplate, { columnNames, rows }, null, ['<%', '%>']);
                field2.$div.after(html);

                field1.$input.prop('disabled', true);
                field2.$input.prop('disabled', true);
                field1.$div.hide();
                field2.$div.hide();

                this.isTable = true;

                this.$scope.closest(this.productViewSelector).addClass('mqpo-productView--table');
            } else {
                // Ignore if the option not found
                const field = this.fieldByLabel[name.toUpperCase()];
                if (!field) {
                    return;
                }

                const type = field.$div.data('productAttribute');

                // Option is Products List or Rectangle
                if (type === 'product-list' || type === 'set-rectangle') {
                    // Generate option item list with quantity box
                    const $input = this.hideNoneOption ? field.$input.not('[value=0]') : field.$input;
                    const list = $input.map((i, el) => {
                        const $el = $(el);
                        const attrId = $el.attr('name').replace(/attribute\[(\d+)]/, '$1');
                        const attrVal = $el.attr('value');
                        return {
                            name: String(this.$scope.find(`label[for=${$el.attr('id')}]`).text()).trim(),
                            imgTag: $el.closest('[data-product-attribute-value]').find('img').first().prop('outerHTML'),
                            id: `${this.productId}_${attrId}_${attrVal}`,
                            attrId,
                            attrVal,
                            min,
                            max,
                        };
                    }).get();
                    const html = Mustache.render(this.productListTemplate, { list, type }, null, ['<%', '%>']);
                    field.$div.append(html);
                    field.$input.prop('disabled', true);
                    field.$inputLabel.hide();
                    field.$div.find(this.productOptionsListSelector).hide();
                }

                this.$scope.closest(this.productViewSelector).addClass('mqpo-productView--list');
            }
        });
    }

    mapFieldByLabel() {
        const fieldByLabel = {};
        this.$scope.find(this.productOptionsSelector).find(this.formFieldSelector).each((i, fieldEl) => {
            const $fieldEl = $(fieldEl);
            const label = String($fieldEl.find(this.optionLabelSelector).first().text()).replace(/\n/g, '').trim().replace(/:.*$/g, '').toUpperCase();
            if (label) {
                fieldByLabel[label] = {
                    $div: $fieldEl,
                    $input: $fieldEl.find('input, select, textarea'),
                    $inputLabel: $fieldEl.find('label[for]'),
                };
            }
        });
        return fieldByLabel;
    }
}

function init(settings) {
    $('form[data-cart-item-add]').each((i, form) => {
        const $form = $(form);
        if ($form.data(`${APPUID}ProductInstance`)) {
            return;
        }
        $form.data(`${APPUID}ProductInstance`, new Product($form, settings));
    });
}

export default function initMultiQtyProductOptions(settings) {
    $(document).ready(() => {
        init(settings);
        const mo = new MutationObserver(debounce(() => {
            init(settings);
        }), 300);
        mo.observe(document.body, { childList: true, subtree: true });
    });
}
